import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { isIOS, isMacOs } from 'react-device-detect';

// import NotFound from './scenes/NotFound';
import Recette from './scenes/Recette';
import RecetteList from './scenes/RecetteList';
import Share from './scenes/Share';
import GlobalStyles from './styles/global';
import { appStoreLink, googlePlayLink } from './utils/links';
import LandingPage from './scenes/LandingPage';

class App extends React.Component {
  state = {
    recettes: [],
  };

  sendParent = (recettes) => {
    this.setState({ recettes: recettes });
  };
  componentDidMount() {
    console.log('mounted');
  }
  componentWillUnmount() {}

  render() {
    return (
      <BrowserRouter>
        <GlobalStyles />
        <Switch>
          <Route path="/recette/:id" component={Recette} />
          <Route
            path="/recette"
            render={(props) => (
              <RecetteList
                sendParent={this.sendParent}
                {...props}
                recettes={this.state.recettes}
                recetteListLoaded={this.state.recetteListLoaded}
              />
            )}
          />
          <Route
            path="/share/qrcode"
            component={() => {
              const isAppleEnv = isIOS || isMacOs;
              window.location.href = isAppleEnv ? appStoreLink : googlePlayLink;
            }}
          />
          <Route path="/share" component={Share} />
          <Route component={LandingPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
