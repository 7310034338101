import React from 'react';
import Header from '../components/Header';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../styles/mediaQueries';
import LoadingPage from './LoadingPage';

class RecetteList extends React.Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    if (!this.props.recettes.length) {
      this.getRecetteListPromise();
    } else {
      this.setState({ loaded: true });
    }
  }

  getRecetteListPromise = async () =>
    fetch(`${process.env.REACT_APP_API}/recette?all=true`)
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((response) => {
        this.props.sendParent(response.data);
        this.setState({ loaded: true });
      });

  render() {
    const { recettes } = this.props;

    if (!this.state.loaded) return <LoadingPage />;

    return (
      <>
        <Header title="Liste des recettes" />
        {/* <SearchBar>
          <input placeholder="Rechercher une recette"></input>
        </SearchBar> */}
        <Title>Liste des recettes</Title>
        <RecettesList>
          {recettes?.map((recette) => {
            return (
              <RecetteContainer key={recette._id}>
                <ThumbContainer>
                  <Link to={`../recette/${recette._id}`}>
                    <RecetteThumb src={recette.pic} alt="Image recette"></RecetteThumb>
                  </Link>
                  <AuthorThumb src={recette.author.thumb}></AuthorThumb>
                </ThumbContainer>
                <RecetteTitleContainer>
                  <h3>{recette.name}</h3>
                </RecetteTitleContainer>
              </RecetteContainer>
            );
          })}
        </RecettesList>
      </>
    );
  }
}

// const SearchBar = styled.div`
//   margin: 20px auto;
//   height: auto;
//   width: 370px;
//   > input {
//     padding-left: 25px;
//     height: 38px;
//     width: 370px;
//     border: 2px solid rgba(0, 0, 0, 0.1);
//     border-radius: 38px;
//     background-color: white;
//     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
//   }
//   > input:focus {
//     outline: none;
//   }
// `;

const Title = styled.h2`
  margin: 40px auto 40px auto;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  ${media.desktop`
  margin: 120px auto 40px auto;
  `}
`;

const RecettesList = styled.div`
  padding: 0 0 40px 0;
  display: grid;
  grid-template-columns: repeat(2, 50vw);
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: auto;
  margin: 0 auto;

  ${media.mobile`
  max-width: 800px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-gap: 20px;
  `}

  ${media.desktop`
  max-width: 1150px;
  grid-template-columns: repeat(auto-fill, 265px);
  `}
`;

const RecetteContainer = styled.div`
  margin: 0 2.5vw 20px 2.5vw;
  display: flex;
  flex-direction: column;

  ${media.mobile`
  margin: 0 0 10px 0;
  `}
`;

const ThumbContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  > a {
    height: 45vw;

    ${media.mobile`
    height: 180px;
  `}

    ${media.desktop`
    height: 265px;
  `}
  }
`;

const RecetteThumb = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 12px;
`;

const AuthorThumb = styled.img`
  height: 30px;
  width: 30px;
  border: 2px solid white;
  border-radius: 30px;
  position: absolute;
  left: 5%;
  bottom: 5%;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06)), drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
  ${media.mobile`
  height: 40px;
  width: 40px;
    `}
`;

const RecetteTitleContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  ${media.desktop`
  margin-top: 15px;
      `}
  > h3 {
    width: 100%;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 500;

    ${media.mobile`
    font-size: 13px;
      `}

    ${media.desktop`
    font-size: 14px;
  `}
  }
`;

export default RecetteList;
