import React from 'react';
import styled from 'styled-components';
import PreparationIcon from '../images/PreparationIcon.js';
import difficulty from '../images/difficulty.svg';
import cost from '../images/cost.svg';
import i18n from '../i18n';
import CookingIcon from '../images/CookingIcon.js';
import { media } from '../styles/mediaQueries';

const RecipeInfo = ({ recette }) => (
  <RecipeInfoContainer>
    <RecipeInfos>
      <PreparationIcon />
      <p>{recette.preparation} min.</p>
    </RecipeInfos>
    <RecipeInfos>
      <CookingIcon />
      <p>{recette.cooking} min.</p>
    </RecipeInfos>
    <RecipeInfos>
      <img src={difficulty} alt="Difficulty"></img>
      <p>{i18n[`difficulty.${recette.difficulty}`]}</p>
    </RecipeInfos>
    <RecipeInfos>
      <img src={cost} alt="Cost"></img>
      <p>{i18n[`cost.${recette.cost}`]}</p>
    </RecipeInfos>
  </RecipeInfoContainer>
);

const RecipeInfoContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  font-size: 9px;
  ${media.desktop`
  font-size: 12px;
  `}
`;

const RecipeInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  & > img,
  svg {
    margin: 5px 0 10px 0;
    height: 30px;
    width: 30px;
    background-size: cover;
  }
`;

export default RecipeInfo;
