import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { media } from '../styles/mediaQueries';

const Title = ({ title }) => (
  <Container>
    <Titles>{i18n[`${title}`]}</Titles>
    <TitleUnderline></TitleUnderline>
  </Container>
);

const Container = styled.div`
  margin-bottom: 20px;
  ${media.desktop`
  margin-bottom: 0;
    `}
`;

const Titles = styled.h3`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
`;

const TitleUnderline = styled.div`
  margin-top: 7px;
  content: '';
  width: 40px;
  height: 2px;
  background-color: #fede4d;
`;

export default Title;

// h3::after {
//     content: "";
//     position: absolute;
//     width: 50px;
//     height: 1px;
//     bottom: -60%;
//     left: 0%;
//     border-bottom: 4px solid #f2c94c;
//   }
