import styled from 'styled-components';
import { media } from '../styles/mediaQueries';

const Fillet = ({ margin = '35px auto' }) => <Fillets margin={margin} />;

const Fillets = styled.div`
  margin: 20px auto;
  width: 100%;
  height: 1px;
  background-color: #e4e4e7;
  ${media.mobile`
  margin: ${(props) => props.margin};
  `};
`;

export default Fillet;
