import React from 'react';
import styled from 'styled-components';
import { media } from '../styles/mediaQueries';

const IngredientsList = ({ ingredients }) => (
  <IngredientContainer>
    <ul>{ingredients}</ul>
  </IngredientContainer>
);

const IngredientContainer = styled.div`
  width: auto;
  ${media.desktop`
  width: 480px;
  `}
  & > ul {
    display: block;
    font-size: 14px;
    ${media.desktop`
    display: flex;
    flex-wrap: wrap;
    `}
  }
  & > ul > li::marker {
    font-size: 14px;
  }
  & > ul > li {
    flex: 0 1;
    margin: 0 15px;
    margin-bottom: 10px;
    ${media.desktop`
    flex: 1 43%;
    `}
  }
`;

export default IngredientsList;
