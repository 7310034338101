import en from './en.json';
import fr from './fr.json';

const i18ns = {
  en,
  fr,
};

const locale = navigator.language.split('-')[0];

const i18n = i18ns[locale] || i18ns.en;

export default i18n;
