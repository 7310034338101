import React from 'react';
import styled from 'styled-components';
import { media } from '../styles/mediaQueries';

const Steps = ({ stepDesc }) => (
  <StepContainer>
    <ol>{stepDesc}</ol>
  </StepContainer>
);

const StepContainer = styled.div`
  width: auto;
  ${media.desktop`
  width: 480px;
    `}
  > ol > li::marker {
    font-family: 'Lobster';
    font-size: 14px;
    color: #fede4d;
  }

  > ol {
    margin-left: 10px;
    font-size: 14px;
  }

  > ol > li {
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

export default Steps;
