import React from 'react';
import styled from 'styled-components';
import Logo from '../images/Logo';
import { appStoreLink, googlePlayLink } from '../utils/links';
import { media } from '../styles/mediaQueries';
import { isIOS, isAndroid } from 'react-device-detect';

import androidLogo from '../images/androidlogo.png';
import applelogo from '../images/applelogo.png';

const Header = () => {
  return (
    <>
      <Head>
        <HeaderContainer>
          <LeftContainer>
            <a href="https://www.le-gratin.app/">
              <Logo color="black" size="60" />
            </a>
            <a href="https://www.le-gratin.app/">
              <Title>Le Gratin</Title>
            </a>
          </LeftContainer>
          <RightContainer>
            <AndroidButton href={googlePlayLink} target="blank">
              <img src={androidLogo} alt="Android logo"></img>
              <span>Download Android</span>
            </AndroidButton>
            <AppleButton href={appStoreLink} target="blank">
              <img src={applelogo} alt="Apple logo"></img>
              <span>Download iOS</span>
            </AppleButton>
          </RightContainer>
          {isIOS ? (
            <DownloadAppButton href={appStoreLink} target="blank">
              <span>Download iOS</span>
            </DownloadAppButton>
          ) : (
            isAndroid && (
              <DownloadAppButton href={googlePlayLink} target="blank">
                <span>Download Android</span>
              </DownloadAppButton>
            )
          )}
        </HeaderContainer>
      </Head>
    </>
  );
};

const Head = styled.header`
  position: relative;
  z-index: 1;
  width: 100vw;
  background-color: #fede4d;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.desktop`
  position: fixed;
  z-index: 99;
  `}
`;

const HeaderContainer = styled.div`
  margin: 0 10px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile`
  margin: 0 20px;
  width: 800px;
  max-width: 800px;
  `}

  ${media.desktop`
  width: 1150px;
  max-width: 1150px;
  `}
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  > a > svg {
    width: 55px;
    margin-right: 10px;

    ${media.mobile`
    width: 60px;
  `}

    ${media.desktop`
    display: block;
    width: 60px;
  `}
  }
`;

const Title = styled.h1`
  text-align: center;
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 600;

  ${media.desktop`
  `};
`;

const RightContainer = styled.div`
  display: none;
  ${media.desktop`
  display: flex;
  `}
`;

const AndroidButton = styled.a`
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 180px;
  height: 40px;
  background-color: black;
  border-radius: 8px;
  > span {
    font-size: 14px;
    color: white;
  }
  > img {
    height: 14px;
  }
`;

const AppleButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
  height: 40px;
  background-color: black;
  border-radius: 8px;
  > span {
    font-size: 14px;
    color: white;
  }
  > img {
    height: 14px;
  }
`;

const DownloadAppButton = styled.a`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  height: 40px;
  background-color: black;
  border-radius: 8px;
  > span {
    color: white;
    font-size: 13px;
  }
  > img {
    width: auto;
    height: auto;
    font-size: 14px;
    color: white;
  }

  ${media.desktop`
display: none;
  `}
`;

export default Header;
