import React from 'react';
import styled from 'styled-components';
import Tags from './Tags';
import Fillet from './Fillet';
import { media } from '../styles/mediaQueries';

const RecipeCardInfos = ({ recette, tags, children }) => (
  <Container>
    <RecipeThumb src={recette.pic}></RecipeThumb>
    <CardTextContainer>
      <Tags tags={tags} />
      <RecipeTitle>{recette.name}</RecipeTitle>
      <Author>
        <AuthorThumb src={recette.author.thumb} />
        <span>Par {recette.author.name}</span>
      </Author>
    </CardTextContainer>
    <Fillet />
    {children}
  </Container>
);

const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 0;
  height: auto;
  box-shadow: none;

  ${media.mobile`
  padding-bottom: 35px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border-bottom: none;
  `}

  ${media.desktop`
  position: fixed;
  margin-top: 200px;
  width: 350px;
  `}
`;

const CardTextContainer = styled.div`
  padding: 0 20px;
`;

const RecipeThumb = styled.img`
  margin-bottom: 20px;
  width: auto;
  height: auto;
  border-radius: 12px 12px 0px 0px;
`;

const RecipeTitle = styled.h3`
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 20px;
`;

const Author = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  > span {
    font-size: 13px;
  }
`;

const AuthorThumb = styled.img`
  margin-right: 5px;
  height: 24px;
  border-radius: 24px;
`;

export default RecipeCardInfos;
