import React from 'react';
import styled from 'styled-components';
import { media } from '../styles/mediaQueries';

const PersonCounter = ({ onMinus, persons, onAdd }) => (
  <Container>
    <Title>Personnes</Title>
    <CounterContainer>
      <CounterMinus onClick={onMinus}>
        <span>&#x02212;</span>
      </CounterMinus>
      <TotalCounter>{persons}</TotalCounter>
      <CounterAdd onClick={onAdd}>
        <span>+</span>
      </CounterAdd>
    </CounterContainer>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 99;
  bottom: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 2px solid #e4e4e7;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  ${media.mobile`
    height: auto;
    position: relative;
    border-top: none;
    box-shadow: none;

  `}
`;

const Title = styled.h3`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
`;

const CounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalCounter = styled.span`
  margin: 0 15px;
`;

const CounterMinus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: white;
  border: 2px solid #fede4d;
  border-radius: 35px;
  > span {
    font-size: 20px;
  }
`;

const CounterAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: white;
  border: 2px solid #fede4d;
  border-radius: 35px;
  > span {
    font-size: 20px;
  }
`;

export default PersonCounter;
