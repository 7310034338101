import React from 'react';
import { Link } from 'react-router-dom';
import { isIOS, isMacOs } from 'react-device-detect';
import styled from 'styled-components';
import FullPageStyle from '../styles/fullPage';
import { appStoreLink, googlePlayLink } from '../utils/links';
import recipeBook from '../images/recipe-book.svg';

const LandingPage = () => {
  const isAppleEnv = isIOS || isMacOs;
  return (
    <>
      <FullPageStyle />
      <Container>
        <AppButton href={isAppleEnv ? appStoreLink : googlePlayLink}>
          <Logo />
          <span>Télécharger</span>
          <span>Le Gratin</span>
        </AppButton>
        <BackButton>
          <Link to="/recette">
            <img src={recipeBook} alt="Recipe list icon"></img>
            <span>Voir la liste des recettes</span>
          </Link>
        </BackButton>
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AppButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  font-family: sans-serif;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const Logo = styled.div`
  background-size: contain;
  background-image: url(${`${process.env.PUBLIC_URL}/logo.png`});
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
`;

const BackButton = styled.div`
  width: 230px;
  margin: 40px 0;
  border-radius: 13px;
  cursor: pointer;
  background-color: #fede4d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border: 1px solid black;
  > a {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  > a > img {
    width: 20px;
    height: auto;
  }
  > a > span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export default LandingPage;
