import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Fillet from './Fillet';
import { media } from '../styles/mediaQueries';

const RecetteSection = ({ title, children }) => (
  <>
    <Fillet />
    <RecetteSectionContainer>
      <Title title={title} />
      {children}
    </RecetteSectionContainer>
  </>
);

const RecetteSectionContainer = styled.div`
  padding: 0 20px;
  display: block;
  ${media.desktop`
  padding: 0;
  display: flex;
  justify-content: space-between;
    `}
`;

export default RecetteSection;
