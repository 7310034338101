import React from 'react';
import styled from 'styled-components';

const Tags = ({ tags }) => (
  <TagsContainer>
    {tags.map((tag) => (
      <Tag key={tag}>{tag}</Tag>
    ))}
  </TagsContainer>
);

const TagsContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  overflow: auto;
`;

const Tag = styled.span`
  white-space: nowrap;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 5px;
  padding: 5px 15px;
  border-radius: 12px;
  background: rgba(254, 222, 77, 0.2);
`;

export default Tags;
