/* eslint-disable max-len */
import React from 'react';

const PreparationIcon = ({ color = '#000000', size = 30, active }) => (
  <svg width={size} height={size} viewBox="0 0 202 198" active={active}>
    <g fill={color} fillRule="nonzero">
      <circle
        transform="translate(33.967610, 182.595707) rotate(-45.000000) translate(-33.967610, -182.595707) "
        cx="33.9676098"
        cy="182.595707"
        r="4.1463017"
      />
      <path d="M82.195122,100.707317 C81.2195122,101.682927 81.2195122,103.146341 82.195122,104.121951 L89.7560976,111.682927 L80.9756098,120.463415 L80.2439024,119.731707 C79.2682927,118.756098 77.804878,118.756098 76.8292683,119.731707 L23.4146341,173.146341 C18.0487805,178.512195 18.0487805,187.536585 23.4146341,192.902439 C26.097561,195.585366 29.5121951,197.04878 33.4146341,197.04878 C37.3170732,197.04878 40.7317073,195.585366 43.4146341,192.902439 L96.8292683,139.487805 C97.3170732,139 97.5609756,138.512195 97.5609756,137.780488 C97.5609756,137.04878 97.3170732,136.560976 96.8292683,136.073171 L96.8292683,136.073171 L200.731707,32.1707317 C201.219512,31.6829268 201.463415,30.9512195 201.463415,30.2195122 C201.463415,29.4878049 200.97561,28.7560976 200.487805,28.5121951 C197.804878,26.5609756 171.95122,11.195122 82.195122,100.707317 Z M39.7560976,189.487805 C36.3414634,192.902439 30.2439024,192.902439 26.8292683,189.487805 C23.1707317,185.829268 23.1707317,180.219512 26.8292683,176.560976 L78.5365854,124.853659 L91.4634146,137.780488 L39.7560976,189.487805 Z M93.1707317,132.658537 L84.3902439,123.878049 L94.8780488,113.390244 C95.8536585,112.414634 95.8536585,110.95122 94.8780488,109.97561 L87.3170732,102.414634 C156.585366,33.6341463 185.609756,30.2195122 194.146341,31.4390244 L93.1707317,132.658537 Z" />
      <path d="M37.0731707,18.2682927 C37.0731707,18.2682927 37.0731707,18.2682927 37.0731707,18.2682927 C34.6341463,18.2682927 32.9268293,20.2195122 32.9268293,22.4146341 C32.9268293,25.097561 36.097561,29 36.3414634,29.2439024 C36.5853659,29.4878049 36.8292683,29.4878049 37.0731707,29.4878049 C37.3170732,29.4878049 37.5609756,29.4878049 37.804878,29.2439024 C38.2926829,28.7560976 41.4634146,25.3414634 41.4634146,22.4146341 C41.4634146,20.2195122 39.5121951,18.2682927 37.0731707,18.2682927 Z" />
      <path d="M37.3170732,42.902439 C37.0731707,42.902439 36.8292683,42.902439 36.5853659,43.1463415 C36.097561,43.6341463 32.9268293,47.0487805 32.9268293,49.9756098 C32.9268293,51.195122 33.4146341,52.1707317 34.1463415,52.902439 C34.8780488,53.6341463 36.097561,54.1219512 37.0731707,54.1219512 C37.0731707,54.1219512 37.0731707,54.1219512 37.0731707,54.1219512 C39.2682927,54.1219512 41.2195122,52.1707317 41.2195122,49.9756098 C41.2195122,49.9756098 41.2195122,49.9756098 41.2195122,49.9756098 C41.2195122,47.2926829 38.0487805,43.3902439 37.804878,43.1463415 C37.804878,42.902439 37.3170732,42.902439 37.3170732,42.902439 Z" />
      <path d="M51.7073171,32.1707317 C49.0243902,32.1707317 45.1219512,35.3414634 44.8780488,35.5853659 C44.6341463,35.8292683 44.6341463,36.0731707 44.6341463,36.3170732 C44.6341463,36.5609756 44.6341463,36.804878 44.8780488,37.0487805 C45.3658537,37.5365854 48.7804878,40.7073171 51.7073171,40.7073171 C51.7073171,40.7073171 51.7073171,40.7073171 51.7073171,40.7073171 C53.902439,40.7073171 55.8536585,38.7560976 55.8536585,36.5609756 C55.8536585,35.3414634 55.3658537,34.3658537 54.6341463,33.6341463 C53.902439,32.902439 52.6829268,32.1707317 51.7073171,32.1707317 C51.7073171,32.1707317 51.7073171,32.1707317 51.7073171,32.1707317 Z" />
      <path d="M23.4146341,40.7073171 C26.097561,40.7073171 30,37.5365854 30.2439024,37.2926829 C30.4878049,37.0487805 30.4878049,36.804878 30.4878049,36.5609756 C30.4878049,36.3170732 30.4878049,36.0731707 30.2439024,35.8292683 C29.7560976,35.3414634 26.3414634,32.1707317 23.4146341,32.1707317 C22.195122,32.1707317 21.2195122,32.6585366 20.4878049,33.3902439 C19.7560976,34.1219512 19.2682927,35.3414634 19.2682927,36.3170732 C19.2682927,37.5365854 19.7560976,38.5121951 20.4878049,39.2439024 C21.2195122,40.2195122 22.195122,40.7073171 23.4146341,40.7073171 C23.4146341,40.7073171 23.4146341,40.7073171 23.4146341,40.7073171 Z" />
      <path d="M42.9268293,31.9268293 C42.9268293,31.9268293 42.9268293,31.9268293 42.9268293,31.9268293 C43.4146341,31.9268293 48.2926829,31.6829268 50.4878049,29.7317073 C51.2195122,29 51.7073171,27.7804878 51.7073171,26.804878 C51.7073171,25.5853659 51.2195122,24.6097561 50.4878049,23.8780488 C49.7560976,23.1463415 48.5365854,22.6585366 47.5609756,22.6585366 C46.3414634,22.6585366 45.3658537,23.1463415 44.6341463,23.8780488 C42.6829268,25.8292683 42.195122,30.7073171 42.195122,31.195122 C42.195122,31.4390244 42.195122,31.6829268 42.4390244,31.9268293 C42.4390244,31.6829268 42.6829268,31.9268293 42.9268293,31.9268293 Z" />
      <path d="M31.9512195,40.7073171 C31.4634146,40.7073171 26.5853659,40.9512195 24.6341463,42.902439 C22.9268293,44.6097561 22.9268293,47.2926829 24.6341463,48.7560976 C25.3658537,49.4878049 26.5853659,49.9756098 27.5609756,49.9756098 C27.5609756,49.9756098 27.5609756,49.9756098 27.5609756,49.9756098 C28.7804878,49.9756098 29.7560976,49.4878049 30.4878049,48.7560976 C32.4390244,46.804878 32.9268293,41.9268293 32.9268293,41.4390244 C32.9268293,41.195122 32.9268293,40.9512195 32.6829268,40.7073171 C32.4390244,40.7073171 32.195122,40.7073171 31.9512195,40.7073171 Z" />
      <path d="M42.9268293,41.195122 C42.6829268,41.195122 42.4390244,41.195122 42.195122,41.4390244 C41.9512195,41.6829268 41.9512195,41.9268293 41.9512195,42.1707317 C41.9512195,42.6585366 42.195122,47.5365854 44.1463415,49.4878049 C44.8780488,50.2195122 46.097561,50.7073171 47.0731707,50.7073171 C47.0731707,50.7073171 47.0731707,50.7073171 47.0731707,50.7073171 C48.2926829,50.7073171 49.2682927,50.2195122 50,49.4878049 C51.7073171,47.7804878 51.7073171,45.097561 50,43.6341463 C48.2926829,41.6829268 43.4146341,41.195122 42.9268293,41.195122 Z" />
      <path d="M31.7073171,31.9268293 C31.7073171,31.9268293 31.7073171,31.9268293 31.7073171,31.9268293 C31.9512195,31.9268293 32.195122,31.9268293 32.4390244,31.6829268 C32.6829268,31.4390244 32.6829268,31.195122 32.6829268,30.9512195 C32.6829268,30.4634146 32.4390244,25.5853659 30.4878049,23.6341463 C29.7560976,22.902439 28.5365854,22.4146341 27.5609756,22.4146341 C27.5609756,22.4146341 27.5609756,22.4146341 27.5609756,22.4146341 C26.3414634,22.4146341 25.3658537,22.902439 24.6341463,23.6341463 C23.902439,24.3658537 23.4146341,25.5853659 23.4146341,26.5609756 C23.4146341,27.7804878 23.902439,28.7560976 24.6341463,29.4878049 C26.3414634,31.4390244 31.2195122,31.9268293 31.7073171,31.9268293 Z" />
      <path d="M37.5609756,8.02439024 C22.195122,8.02439024 9.51219512,20.4634146 9.51219512,36.0731707 C9.51219512,51.6829268 21.9512195,64.1219512 37.5609756,64.1219512 C53.1707317,64.1219512 65.6097561,51.6829268 65.6097561,36.0731707 C65.6097561,20.4634146 52.9268293,8.02439024 37.5609756,8.02439024 Z M37.5609756,59.2439024 C24.8780488,59.2439024 14.3902439,49 14.3902439,36.0731707 C14.3902439,23.1463415 24.6341463,12.902439 37.5609756,12.902439 C50.4878049,12.902439 60.7317073,23.1463415 60.7317073,36.0731707 C60.7317073,49 50.2439024,59.2439024 37.5609756,59.2439024 Z" />
      <path d="M27.5609756,93.6341463 C27.804878,93.6341463 28.0487805,93.6341463 28.2926829,93.3902439 C28.7804878,92.902439 31.9512195,89.4878049 31.9512195,86.5609756 C31.9512195,84.1219512 30,82.4146341 27.804878,82.4146341 C27.804878,82.4146341 27.804878,82.4146341 27.804878,82.4146341 C25.6097561,82.4146341 23.6585366,84.3658537 23.6585366,86.5609756 C23.6585366,89.2439024 26.8292683,93.1463415 27.0731707,93.3902439 C27.0731707,93.6341463 27.3170732,93.6341463 27.5609756,93.6341463 Z" />
      <path d="M27.5609756,118.268293 C27.5609756,118.268293 27.5609756,118.268293 27.5609756,118.268293 C30,118.268293 31.7073171,116.317073 31.7073171,114.121951 C31.7073171,114.121951 31.7073171,114.121951 31.7073171,114.121951 C31.7073171,111.439024 28.5365854,107.536585 28.2926829,107.292683 C28.0487805,107.04878 27.804878,107.04878 27.5609756,107.04878 C27.3170732,107.04878 27.0731707,107.04878 26.8292683,107.292683 C26.3414634,107.780488 23.1707317,111.195122 23.1707317,114.121951 C23.1707317,115.341463 23.6585366,116.317073 24.3902439,117.04878 C25.3658537,117.780488 26.3414634,118.268293 27.5609756,118.268293 Z" />
      <path d="M34.8780488,100.463415 C34.8780488,100.707317 34.8780488,100.95122 35.1219512,101.195122 C35.6097561,101.682927 39.0243902,104.853659 41.9512195,104.853659 C41.9512195,104.853659 41.9512195,104.853659 41.9512195,104.853659 C43.1707317,104.853659 44.1463415,104.365854 44.8780488,103.634146 C45.6097561,102.902439 46.097561,101.682927 46.097561,100.707317 C46.097561,99.7317073 45.6097561,98.5121951 44.8780488,97.7804878 C44.1463415,97.0487805 42.9268293,96.5609756 41.9512195,96.5609756 C41.9512195,96.5609756 41.9512195,96.5609756 41.9512195,96.5609756 C39.2682927,96.5609756 35.3658537,99.7317073 35.1219512,99.9756098 C34.8780488,99.9756098 34.8780488,100.219512 34.8780488,100.463415 Z" />
      <path d="M13.6585366,104.609756 C16.3414634,104.609756 20.2439024,101.439024 20.4878049,101.195122 C20.7317073,100.95122 20.7317073,100.707317 20.7317073,100.463415 C20.7317073,100.219512 20.7317073,99.9756098 20.4878049,99.7317073 C20,99.2439024 16.5853659,96.0731707 13.6585366,96.0731707 C13.6585366,96.0731707 13.6585366,96.0731707 13.6585366,96.0731707 C11.4634146,96.0731707 9.51219512,98.0243902 9.51219512,100.219512 C9.51219512,101.439024 10,102.414634 10.7317073,103.146341 C11.4634146,104.121951 12.6829268,104.609756 13.6585366,104.609756 C13.6585366,104.609756 13.6585366,104.609756 13.6585366,104.609756 Z" />
      <path d="M33.4146341,95.8292683 C33.4146341,95.8292683 33.4146341,95.8292683 33.4146341,95.8292683 C33.902439,95.8292683 38.7804878,95.5853659 40.9756098,93.6341463 C41.7073171,92.902439 42.195122,91.6829268 42.195122,90.7073171 C42.195122,89.4878049 41.7073171,88.5121951 40.9756098,87.7804878 C40.2439024,87.0487805 39.0243902,86.5609756 38.0487805,86.5609756 C38.0487805,86.5609756 38.0487805,86.5609756 38.0487805,86.5609756 C36.8292683,86.5609756 35.8536585,87.0487805 35.1219512,87.7804878 C33.1707317,89.7317073 32.6829268,94.6097561 32.6829268,95.097561 C32.6829268,95.3414634 32.6829268,95.5853659 32.9268293,95.8292683 C32.9268293,95.5853659 33.1707317,95.8292683 33.4146341,95.8292683 Z" />
      <path d="M22.4390244,104.609756 C21.9512195,104.609756 17.0731707,104.853659 15.1219512,106.804878 C14.3902439,107.536585 13.902439,108.756098 13.902439,109.731707 C13.902439,110.95122 14.3902439,111.926829 15.1219512,112.658537 C15.8536585,113.390244 17.0731707,113.878049 18.0487805,113.878049 C19.0243902,113.878049 20.2439024,113.390244 20.9756098,112.658537 C22.9268293,110.707317 23.4146341,105.829268 23.4146341,105.341463 C23.4146341,105.097561 23.4146341,104.853659 23.1707317,104.609756 C22.9268293,104.609756 22.6829268,104.609756 22.4390244,104.609756 Z" />
      <path d="M33.4146341,105.097561 C33.1707317,105.097561 32.9268293,105.097561 32.6829268,105.341463 C32.4390244,105.585366 32.4390244,105.829268 32.4390244,106.073171 C32.4390244,106.560976 32.6829268,111.439024 34.6341463,113.390244 C35.3658537,114.121951 36.5853659,114.609756 37.5609756,114.609756 C37.5609756,114.609756 37.5609756,114.609756 37.5609756,114.609756 C38.7804878,114.609756 39.7560976,114.121951 40.4878049,113.390244 C41.2195122,112.658537 41.7073171,111.439024 41.7073171,110.463415 C41.7073171,109.487805 41.2195122,108.268293 40.4878049,107.536585 C38.7804878,105.585366 33.902439,105.097561 33.4146341,105.097561 Z" />
      <path d="M22.195122,95.8292683 C22.195122,95.8292683 22.195122,95.8292683 22.195122,95.8292683 C22.4390244,95.8292683 22.6829268,95.8292683 22.9268293,95.5853659 C23.1707317,95.3414634 23.1707317,95.097561 23.1707317,94.8536585 C23.1707317,94.3658537 22.9268293,89.4878049 20.9756098,87.5365854 C19.2682927,85.8292683 16.5853659,85.8292683 15.1219512,87.5365854 C13.6585366,89.2439024 13.4146341,91.9268293 15.1219512,93.3902439 C16.8292683,95.5853659 21.7073171,95.8292683 22.195122,95.8292683 Z" />
      <path d="M27.804878,128.02439 C43.1707317,128.02439 55.8536585,115.585366 55.8536585,99.9756098 C55.8536585,84.3658537 43.4146341,72.1707317 27.804878,72.1707317 C12.195122,72.1707317 0,84.6097561 0,99.9756098 C0,115.341463 12.4390244,128.02439 27.804878,128.02439 Z M27.804878,77.0487805 C40.4878049,77.0487805 50.9756098,87.2926829 50.9756098,100.219512 C50.9756098,113.146341 40.7317073,123.390244 27.804878,123.390244 C14.8780488,123.390244 4.87804878,112.902439 4.87804878,99.9756098 C4.87804878,87.0487805 15.1219512,77.0487805 27.804878,77.0487805 Z" />
      <path d="M166.585366,117.536585 C166.829268,117.536585 167.073171,117.536585 167.317073,117.292683 C167.804878,116.804878 170.97561,113.390244 170.97561,110.463415 C170.97561,109.243902 170.487805,108.268293 169.756098,107.536585 C169.02439,106.804878 167.804878,106.317073 166.829268,106.317073 C166.829268,106.317073 166.829268,106.317073 166.829268,106.317073 C164.634146,106.317073 162.682927,108.268293 162.682927,110.463415 C162.682927,110.463415 162.682927,110.463415 162.682927,110.463415 C162.682927,113.146341 165.853659,117.04878 166.097561,117.292683 C166.097561,117.292683 166.341463,117.292683 166.585366,117.536585 Z" />
      <path d="M166.585366,141.926829 C166.585366,141.926829 166.585366,141.926829 166.585366,141.926829 C169.02439,141.926829 170.731707,139.97561 170.731707,137.780488 C170.731707,135.097561 167.560976,131.195122 167.317073,130.95122 C167.073171,130.707317 166.829268,130.707317 166.585366,130.707317 C166.585366,130.707317 166.585366,130.707317 166.585366,130.707317 C166.341463,130.707317 166.097561,130.707317 165.853659,130.95122 C165.365854,131.439024 162.195122,134.853659 162.195122,137.780488 C162.439024,139.97561 164.390244,141.926829 166.585366,141.926829 Z" />
      <path d="M173.902439,124.121951 C173.902439,124.365854 173.902439,124.609756 174.146341,124.853659 C174.634146,125.341463 178.04878,128.512195 180.97561,128.512195 C180.97561,128.512195 180.97561,128.512195 180.97561,128.512195 C183.170732,128.512195 185.121951,126.560976 185.121951,124.365854 C185.121951,123.146341 184.634146,122.170732 183.902439,121.439024 C183.170732,120.707317 181.95122,120.219512 180.97561,120.219512 C180.97561,120.219512 180.97561,120.219512 180.97561,120.219512 C178.292683,120.219512 174.390244,123.390244 174.146341,123.634146 C173.902439,123.634146 173.902439,123.878049 173.902439,124.121951 Z" />
      <path d="M152.682927,128.268293 C155.365854,128.268293 159.268293,125.097561 159.512195,124.853659 C159.756098,124.609756 159.756098,124.365854 159.756098,124.121951 C159.756098,123.878049 159.756098,123.634146 159.512195,123.390244 C159.02439,122.902439 155.609756,119.731707 152.682927,119.731707 C152.682927,119.731707 152.682927,119.731707 152.682927,119.731707 C150.487805,119.731707 148.536585,121.682927 148.536585,123.878049 C148.536585,126.560976 150.487805,128.268293 152.682927,128.268293 C152.682927,128.268293 152.682927,128.268293 152.682927,128.268293 Z" />
      <path d="M172.439024,119.487805 C172.439024,119.487805 172.439024,119.487805 172.439024,119.487805 C172.926829,119.487805 177.804878,119.243902 180,117.292683 C180.731707,116.560976 181.219512,115.341463 181.219512,114.365854 C181.219512,113.146341 180.731707,112.170732 180,111.439024 C179.268293,110.707317 178.04878,110.219512 177.073171,110.219512 C177.073171,110.219512 177.073171,110.219512 177.073171,110.219512 C175.853659,110.219512 174.878049,110.707317 174.146341,111.439024 C172.195122,113.390244 171.707317,118.268293 171.707317,118.756098 C171.707317,119 171.707317,119.243902 171.95122,119.487805 C171.95122,119.487805 172.195122,119.487805 172.439024,119.487805 Z" />
      <path d="M161.463415,128.268293 C160.97561,128.268293 156.097561,128.512195 154.146341,130.463415 C153.414634,131.195122 152.926829,132.414634 152.926829,133.390244 C152.926829,134.609756 153.414634,135.585366 154.146341,136.317073 C154.878049,137.04878 156.097561,137.536585 157.073171,137.536585 C158.04878,137.536585 159.268293,137.04878 160,136.317073 C161.95122,134.365854 162.439024,129.487805 162.439024,129 C162.439024,128.756098 162.439024,128.512195 162.195122,128.268293 C161.95122,128.512195 161.707317,128.268293 161.463415,128.268293 Z" />
      <path d="M172.439024,129 C172.195122,129 171.95122,129 171.707317,129.243902 C171.463415,129.487805 171.463415,129.731707 171.463415,129.97561 C171.463415,130.463415 171.707317,135.341463 173.658537,137.292683 C174.390244,138.02439 175.609756,138.512195 176.585366,138.512195 C177.560976,138.512195 178.780488,138.02439 179.512195,137.292683 C180.243902,136.560976 180.731707,135.341463 180.731707,134.365854 C180.731707,133.146341 180.243902,132.170732 179.512195,131.439024 C177.804878,129.243902 172.926829,129 172.439024,129 Z" />
      <path d="M161.219512,119.731707 C161.219512,119.731707 161.219512,119.731707 161.219512,119.731707 C161.463415,119.731707 161.707317,119.731707 161.95122,119.487805 C162.195122,119.243902 162.195122,119 162.195122,118.756098 C162.195122,118.268293 161.95122,113.390244 160,111.439024 C159.268293,110.707317 158.04878,110.219512 157.073171,110.219512 C157.073171,110.219512 157.073171,110.219512 157.073171,110.219512 C155.853659,110.219512 154.878049,110.707317 154.146341,111.439024 C153.414634,112.170732 152.926829,113.390244 152.926829,114.365854 C152.926829,115.585366 153.414634,116.560976 154.146341,117.292683 C155.853659,119.243902 160.731707,119.487805 161.219512,119.731707 Z" />
      <path d="M166.829268,151.682927 C182.195122,151.682927 194.878049,139.243902 194.878049,123.634146 C194.878049,108.02439 182.439024,95.5853659 166.829268,95.5853659 C151.219512,95.5853659 139.02439,108.268293 139.02439,123.878049 C139.02439,139.487805 151.463415,151.682927 166.829268,151.682927 Z M166.829268,100.707317 C179.512195,100.707317 190,110.95122 190,123.878049 C190,136.804878 179.756098,147.04878 166.829268,147.04878 C153.902439,147.04878 143.902439,136.560976 143.902439,123.878049 C143.902439,111.195122 154.146341,100.707317 166.829268,100.707317 Z" />
      <path d="M125.609756,149.487805 C123.414634,149.487805 121.463415,151.439024 121.463415,153.634146 C121.463415,156.317073 124.634146,160.219512 124.878049,160.463415 C125.121951,160.707317 125.365854,160.707317 125.609756,160.707317 C125.853659,160.707317 126.097561,160.707317 126.341463,160.463415 C126.829268,159.97561 130,156.560976 130,153.634146 C129.756098,151.439024 128.04878,149.487805 125.609756,149.487805 C125.609756,149.487805 125.609756,149.487805 125.609756,149.487805 Z" />
      <path d="M125.609756,174.121951 C125.609756,174.121951 125.609756,174.121951 125.609756,174.121951 C125.365854,174.121951 125.121951,174.121951 124.878049,174.365854 C124.390244,174.853659 121.219512,178.268293 121.219512,181.195122 C121.219512,182.414634 121.707317,183.390244 122.439024,184.121951 C123.170732,184.853659 124.390244,185.341463 125.365854,185.341463 C125.365854,185.341463 125.365854,185.341463 125.365854,185.341463 C127.560976,185.341463 129.512195,183.390244 129.512195,181.195122 C129.512195,181.195122 129.512195,181.195122 129.512195,181.195122 C129.512195,178.512195 126.341463,174.609756 126.097561,174.365854 C126.097561,174.121951 125.853659,174.121951 125.609756,174.121951 Z" />
      <path d="M132.926829,167.536585 C132.926829,167.780488 132.926829,168.02439 133.170732,168.268293 C133.658537,168.756098 137.073171,171.926829 140,171.926829 C140,171.926829 140,171.926829 140,171.926829 C142.195122,171.926829 144.146341,169.97561 144.146341,167.780488 C144.146341,165.341463 142.195122,163.634146 140,163.634146 C140,163.634146 140,163.634146 140,163.634146 C137.317073,163.634146 133.414634,166.804878 133.170732,167.04878 C132.926829,167.04878 132.926829,167.292683 132.926829,167.536585 Z" />
      <path d="M111.707317,171.926829 C114.390244,171.926829 118.292683,168.756098 118.536585,168.512195 C118.780488,168.268293 118.780488,168.02439 118.780488,167.780488 C118.780488,167.536585 118.780488,167.292683 118.536585,167.04878 C118.04878,166.560976 114.634146,163.390244 111.707317,163.390244 C111.707317,163.390244 111.707317,163.390244 111.707317,163.390244 C109.512195,163.390244 107.560976,165.341463 107.560976,167.536585 C107.560976,168.756098 108.04878,169.731707 108.780488,170.463415 C109.512195,171.439024 110.731707,171.926829 111.707317,171.926829 C111.707317,171.926829 111.707317,171.926829 111.707317,171.926829 Z" />
      <path d="M131.219512,162.902439 C131.463415,162.902439 131.463415,162.902439 131.219512,162.902439 C131.707317,162.902439 136.585366,162.658537 138.780488,160.707317 C139.512195,159.97561 140,158.756098 140,157.780488 C140,156.560976 139.512195,155.585366 138.780488,154.853659 C138.04878,154.121951 136.829268,153.634146 135.853659,153.634146 C135.853659,153.634146 135.853659,153.634146 135.853659,153.634146 C134.634146,153.634146 133.658537,154.121951 132.926829,154.853659 C130.97561,156.804878 130.487805,161.682927 130.487805,162.170732 C130.487805,162.414634 130.487805,162.658537 130.731707,162.902439 C130.97561,162.902439 131.219512,162.902439 131.219512,162.902439 Z" />
      <path d="M120.487805,171.926829 C120,171.926829 115.121951,172.170732 113.170732,174.121951 C112.439024,174.853659 111.95122,176.073171 111.95122,177.04878 C111.95122,178.268293 112.439024,179.243902 113.170732,179.97561 C113.902439,180.707317 115.121951,181.195122 116.097561,181.195122 C116.097561,181.195122 116.097561,181.195122 116.097561,181.195122 C117.317073,181.195122 118.292683,180.707317 119.02439,179.97561 C120.97561,178.02439 121.463415,173.146341 121.463415,172.658537 C121.463415,172.414634 121.463415,172.170732 121.219512,171.926829 C120.97561,171.926829 120.731707,171.926829 120.487805,171.926829 Z" />
      <path d="M131.463415,172.414634 C131.219512,172.414634 130.97561,172.414634 130.731707,172.658537 C130.487805,172.902439 130.487805,173.146341 130.487805,173.390244 C130.487805,173.878049 130.731707,178.756098 132.682927,180.707317 C133.414634,181.439024 134.634146,181.926829 135.609756,181.926829 C135.609756,181.926829 135.609756,181.926829 135.609756,181.926829 C136.829268,181.926829 137.804878,181.439024 138.536585,180.707317 C139.268293,179.97561 139.756098,178.756098 139.756098,177.780488 C139.756098,176.560976 139.268293,175.585366 138.536585,174.853659 C136.829268,172.902439 131.95122,172.414634 131.463415,172.414634 Z" />
      <path d="M120.243902,163.146341 C120.243902,163.146341 120.243902,163.146341 120.243902,163.146341 C120.487805,163.146341 120.731707,163.146341 120.97561,162.902439 C121.219512,162.658537 121.219512,162.414634 121.219512,162.170732 C121.219512,161.682927 120.97561,156.804878 119.02439,154.853659 C118.292683,154.121951 117.073171,153.634146 116.097561,153.634146 C116.097561,153.634146 116.097561,153.634146 116.097561,153.634146 C114.878049,153.634146 113.902439,154.121951 113.170732,154.853659 C111.463415,156.560976 111.463415,159.243902 113.170732,160.707317 C114.878049,162.658537 119.756098,163.146341 120.243902,163.146341 Z" />
      <path d="M98.0487805,167.292683 C98.0487805,182.658537 110.487805,195.341463 126.097561,195.341463 C141.707317,195.341463 153.902439,182.658537 153.902439,167.292683 C153.902439,151.926829 141.463415,139.243902 125.853659,139.243902 C110.243902,139.243902 98.0487805,151.926829 98.0487805,167.292683 Z M149.02439,167.292683 C149.02439,179.97561 138.780488,190.463415 125.853659,190.463415 C112.926829,190.463415 102.682927,180.219512 102.682927,167.292683 C102.682927,154.365854 112.926829,144.121951 125.853659,144.121951 C138.780488,144.121951 149.02439,154.609756 149.02439,167.292683 Z" />
      <path d="M106.341463,22.4146341 C106.585366,22.4146341 106.829268,22.4146341 107.073171,22.1707317 C107.560976,21.6829268 110.731707,18.2682927 110.731707,15.3414634 C110.731707,12.902439 108.780488,11.195122 106.585366,11.195122 C106.585366,11.195122 106.585366,11.195122 106.585366,11.195122 C104.390244,11.195122 102.439024,13.1463415 102.439024,15.3414634 C102.439024,18.0243902 105.609756,21.9268293 105.853659,22.1707317 C105.853659,22.4146341 106.341463,22.4146341 106.341463,22.4146341 Z" />
      <path d="M106.341463,47.0487805 C106.585366,47.0487805 106.585366,47.0487805 106.341463,47.0487805 C108.780488,47.0487805 110.487805,45.097561 110.487805,42.902439 C110.487805,42.902439 110.487805,42.902439 110.487805,42.902439 C110.487805,40.2195122 107.317073,36.3170732 107.073171,36.0731707 C106.829268,35.8292683 106.585366,35.8292683 106.341463,35.8292683 C106.341463,35.8292683 106.341463,35.8292683 106.341463,35.8292683 C106.097561,35.8292683 105.853659,35.8292683 105.609756,36.0731707 C105.121951,36.5609756 101.95122,39.9756098 101.95122,42.902439 C101.95122,44.1219512 102.439024,45.097561 103.170732,45.8292683 C104.390244,46.5609756 105.365854,47.0487805 106.341463,47.0487805 Z" />
      <path d="M113.658537,29.2439024 C113.658537,29.4878049 113.658537,29.7317073 113.902439,29.9756098 C114.390244,30.4634146 117.804878,33.6341463 120.731707,33.6341463 C120.731707,33.6341463 120.731707,33.6341463 120.731707,33.6341463 C121.95122,33.6341463 122.926829,33.1463415 123.658537,32.4146341 C124.390244,31.6829268 124.878049,30.4634146 124.878049,29.4878049 C124.878049,27.0487805 122.926829,25.3414634 120.731707,25.3414634 C117.804878,25.3414634 114.146341,28.5121951 113.902439,28.7560976 C113.902439,28.7560976 113.658537,29 113.658537,29.2439024 Z" />
      <path d="M92.6829268,33.3902439 C95.3658537,33.3902439 99.2682927,30.2195122 99.5121951,29.9756098 C99.7560976,29.7317073 99.7560976,29.4878049 99.7560976,29.2439024 C99.7560976,29 99.7560976,28.7560976 99.5121951,28.5121951 C99.0243902,28.0243902 95.6097561,24.8536585 92.6829268,24.8536585 C92.6829268,24.8536585 92.6829268,24.8536585 92.6829268,24.8536585 C90.4878049,24.8536585 88.5365854,26.804878 88.5365854,29 C88.5365854,30.2195122 89.0243902,31.195122 89.7560976,31.9268293 C90.4878049,32.902439 91.4634146,33.3902439 92.6829268,33.3902439 C92.6829268,33.3902439 92.6829268,33.3902439 92.6829268,33.3902439 Z" />
      <path d="M112.195122,24.6097561 C112.195122,24.6097561 112.195122,24.6097561 112.195122,24.6097561 C112.682927,24.6097561 117.560976,24.3658537 119.756098,22.4146341 C120.487805,21.6829268 120.97561,20.4634146 120.97561,19.4878049 C120.97561,18.2682927 120.487805,17.2926829 119.756098,16.5609756 C118.04878,14.8536585 115.365854,14.8536585 113.902439,16.5609756 C111.95122,18.5121951 111.463415,23.3902439 111.463415,23.8780488 C111.463415,24.1219512 111.463415,24.3658537 111.707317,24.6097561 C111.707317,24.6097561 111.95122,24.6097561 112.195122,24.6097561 Z" />
      <path d="M101.219512,33.3902439 C100.731707,33.3902439 95.8536585,33.6341463 93.902439,35.5853659 C93.1707317,36.3170732 92.6829268,37.5365854 92.6829268,38.5121951 C92.6829268,39.7317073 93.1707317,40.7073171 93.902439,41.4390244 C94.6341463,42.1707317 95.8536585,42.6585366 96.8292683,42.6585366 C97.804878,42.6585366 99.0243902,42.1707317 99.7560976,41.4390244 C101.707317,39.4878049 102.195122,34.6097561 102.195122,34.1219512 C102.195122,33.8780488 102.195122,33.6341463 101.95122,33.3902439 C101.707317,33.3902439 101.463415,33.3902439 101.219512,33.3902439 Z" />
      <path d="M112.195122,33.8780488 C111.95122,33.8780488 111.707317,33.8780488 111.463415,34.1219512 C111.219512,34.3658537 111.219512,34.6097561 111.219512,34.8536585 C111.219512,35.3414634 111.463415,40.2195122 113.414634,42.1707317 C114.146341,42.902439 115.365854,43.3902439 116.341463,43.3902439 C116.341463,43.3902439 116.341463,43.3902439 116.341463,43.3902439 C117.560976,43.3902439 118.536585,42.902439 119.268293,42.1707317 C120,41.4390244 120.487805,40.2195122 120.487805,39.2439024 C120.487805,38.0243902 120,37.0487805 119.268293,36.3170732 C117.804878,34.3658537 112.926829,34.1219512 112.195122,33.8780488 Z" />
      <path d="M100.97561,24.6097561 C100.97561,24.6097561 101.219512,24.6097561 100.97561,24.6097561 C101.219512,24.6097561 101.463415,24.6097561 101.707317,24.3658537 C101.95122,24.1219512 101.95122,23.8780488 101.95122,23.6341463 C101.95122,23.1463415 101.707317,18.2682927 99.7560976,16.3170732 C99.0243902,15.5853659 97.804878,15.097561 96.8292683,15.097561 C96.8292683,15.097561 96.8292683,15.097561 96.8292683,15.097561 C95.6097561,15.097561 94.6341463,15.5853659 93.902439,16.3170732 C92.195122,18.0243902 92.195122,20.7073171 93.902439,22.1707317 C95.6097561,24.3658537 100.487805,24.6097561 100.97561,24.6097561 Z" />
      <path d="M106.829268,56.804878 C122.195122,56.804878 134.878049,44.3658537 134.878049,28.7560976 C134.878049,13.1463415 122.195122,0.951219512 106.829268,0.951219512 C91.4634146,0.951219512 78.7804878,13.3902439 78.7804878,29 C78.7804878,44.6097561 91.4634146,56.804878 106.829268,56.804878 Z M106.829268,5.82926829 C119.512195,5.82926829 130,16.0731707 130,29 C130,41.6829268 119.756098,52.1707317 106.829268,52.1707317 C93.902439,52.1707317 83.6585366,41.9268293 83.6585366,29 C83.6585366,16.0731707 94.1463415,5.82926829 106.829268,5.82926829 Z" />
    </g>
  </svg>
);

export default PreparationIcon;
