import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import PersonCounter from '../components/PersonCounter';
import RecipeInfo from '../components/RecipeInfo';
import RecetteSection from '../components/RecetteSection';
import IngredientsList from '../components/IngredientsList';
import Steps from '../components/Steps';
import Footer from '../components/Footer';
import RecipeCardInfos from '../components/RecipeCardInfos';
import { media } from '../styles/mediaQueries';
import recipeBook from '../images/recipe-book.svg';
import LoadingPage from './LoadingPage';

import i18n from '../i18n';
import styled from 'styled-components';

// stateful
class Recette extends React.Component {
  state = {
    recette: {},
    initPersons: 4,
    persons: 4,
    loaded: false,
  };

  componentDidMount() {
    this.getRecettePromise();
  }

  getRecettePromise = () => {
    const recetteId = this.props.match.params.id;
    fetch(`${process.env.REACT_APP_API}/recette/${recetteId}?json=true`)
      .then((res) => {
        if (res.ok) return res.json();
        else if (!res.ok) {
          window.location.href = 'https://www.le-gratin.app';
          alert("Cette recette n'existe pas");
        }
      })
      .then((response) => {
        this.setState({
          recette: response.data,
          initPersons: response.data.persons,
          persons: response.data.persons,
          loaded: true,
        });
      });
  };

  navigationButton = (path) => {
    this.props.history.push(path);
  };

  onMinus = () => {
    this.setState({ persons: this.state.persons - 1 });
  };

  onAdd = () => {
    this.setState({ persons: this.state.persons + 1 });
  };

  render() {
    const { recette, initPersons, persons, loaded } = this.state;

    if (!loaded) return <LoadingPage />;

    const { categories, regimes } = this.state.recette;

    // recette.families: [{ _id: 1234, label: 'Pasta' }, { _id: 3456, label: 'Pizza' }]

    const tags = [
      ...recette.families.map((f) => f.label), // ['Pasta', 'Pizza']
      ...Object.keys(categories)
        .filter((key) => categories[key])
        .map((key) => i18n[`category.${key}`]),
      ...Object.keys(regimes)
        .filter((key) => regimes[key])
        .map((key) => i18n[`regime.${key}`]),
    ];

    const stepDesc =
      recette?.steps?.map((steps) => {
        return <li key={steps._id}>{steps.description}</li>;
      }) || [];

    const ingredients =
      recette?.ingredients?.map((ing) => {
        const formula = (ing.quantity / initPersons) * persons;
        return (
          <li key={ing.ingredient._id}>
            <b>{ing.ingredient.name} : </b>
            {formula} {i18n[`unit.${formula > 1 ? 'plural' : 'label'}.${ing.unit}`]};
          </li>
        );
      }) || [];

    return (
      <>
        <Header backButton goBack={this.goBack} />
        <Background>
          <ButtonContainer>
            <BackButton>
              <Link to="./">
                <img src={recipeBook} alt="Recipe list icon"></img>
                <span>Voir la liste des recettes</span>
              </Link>
            </BackButton>
            <UserButton>Like - Share - Etc.</UserButton>
          </ButtonContainer>
          <RecipeContainer>
            <LeftSection>
              <RecipeCardInfos recette={recette} tags={tags} margin="200px">
                {window.innerWidth > 400 && (
                  <PersonCounter persons={persons} onMinus={this.onMinus} onAdd={this.onAdd} />
                )}
              </RecipeCardInfos>
            </LeftSection>

            <RightSection>
              <CookingInfos>
                <RecipeInfo recette={recette} />
                {!!recette.description && (
                  <RecetteSection title="description">
                    <Description>{recette.description}</Description>
                  </RecetteSection>
                )}
                <RecetteSection title="ingredients">
                  <IngredientsList ingredients={ingredients} />
                </RecetteSection>
                <RecetteSection title="steps">
                  <Steps stepDesc={stepDesc} />
                </RecetteSection>
              </CookingInfos>
              <Footer />
            </RightSection>
          </RecipeContainer>
          {window.innerWidth < 400 && (
            <PersonCounter persons={persons} onMinus={this.onMinus} onAdd={this.onAdd} />
          )}{' '}
        </Background>
      </>
    );
  }
}

const Background = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  ${media.mobile`
  background-color: #f9fafb;
  `};
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-top: 0;

  ${media.mobile`
  justify-content: flex-start;
  `};

  ${media.desktop`
  justify-content: flex-start;
  margin-top: 80px;
  max-width: 1150px;
  position: fixed;
  z-index: 99;
  `};
`;

const BackButton = styled.div`
  width: 230px;
  margin: 40px 0;
  border-radius: 13px;
  cursor: pointer;
  background-color: #fede4d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);

  ${media.desktop`
  margin: 40px 0;
  `};
  > a {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  > a > img {
    width: 20px;
    height: auto;
  }
  > a > span {
    font-size: 14px;
    font-weight: 500;
  }
`;

const RecipeContainer = styled.div`
  width: 100vw;
  max-width: 400px;
  display: block;

  ${media.desktop`
  max-width: 1150px;
  width: 1150px;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: transparent;
  `}
`;

const LeftSection = styled.div`
  display: block;
`;

const RightSection = styled.div`
  margin: 0;
  height: auto;

  ${media.mobile`
  margin: 30px 0;
  `};

  ${media.desktop`
  padding-top: 200px;
  margin: 0;
  `};
`;

const UserButton = styled.div`
  display: none;
  ${media.desktop`
  display: none;
  `};
`;

const CookingInfos = styled.div`
  padding: 0 0 35px 0;
  width: auto;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  ${media.mobile`
  padding: 35px 0;
    `}

  ${media.desktop`
  padding: 50px;
  width: 730px;
    `}
`;

const Description = styled.p`
  width: auto;
  font-size: 14px;
  ${media.desktop`
  width: 480px;
    `}
`;

// const ListeCourse = styled.div`;
//   display: none;
//   position: absolute;
//   align-items: center;
//   justify-content: center;
//   z-index: 99;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 100%;
//   border: 1px solid green;
//   > div {
//     height: 500px;
//     width: 80%;
//     background-color: white;
//     border: 1px solid red;
//   }
// `;

export default Recette;
