import React from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import logo from '../images/Logo.js';
import FullPageStyle from '../styles/fullPage.js';

class QRCodeShare extends React.Component {
  render() {
    return (
      <>
        <FullPageStyle />
        <ContainerQr>
          <Title>Scannez-moi pour télécharger l'application !</Title>
          {window.innerWidth < 400 ? (
            <QRCode
              value={`${process.env.REACT_APP_API}/share/qrcode`}
              size={280}
              level="H"
              bgColor="none"
              fgColor="black"
            />
          ) : (
            <QRCode
              value={`${process.env.REACT_APP_API}/share/qrcode`}
              size={400}
              level="H"
              bgColor="none"
              fgColor="black"
            />
          )}
          <Logo src={logo}></Logo>
        </ContainerQr>
      </>
    );
  }
}

const ContainerQr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  border: 1px solid green;
  background-color: #fede4d;
  > svg {
  }
`;

const Title = styled.h2`
  margin-bottom: 60px;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
`;

const Logo = styled.img`
  display: none;
  position: absolute;
  width: 202px;
  height: 202px;
  background-color: white;
`;

export default QRCodeShare;
