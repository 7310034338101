import { createGlobalStyle } from 'styled-components';

const FullPageStyle = createGlobalStyle`
  #main {
    max-width: 100% !important;
    height: auto;
    background-color: #fede4d;
  }
`;

export default FullPageStyle;
