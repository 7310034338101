import React from 'react';
import Logo from '../images/Logo.js';
import styled from 'styled-components';
import FullPageStyle from '../styles/fullPage';

const LoadingPage = () => (
  <>
    <FullPageStyle />
    <Container>
      <Logo size="200" animated />
      <LoaderExempleContainer />
    </Container>
  </>
);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderExempleContainer = styled.div`
  position: absolute;
  bottom: 100px;
  width: 90vw;
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
  grid-gap: 100px;
`;

export default LoadingPage;
